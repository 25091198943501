import {
  PaymentControllerService,
  PaymentInfoSetVo,
  RegularPayInfoVo,
  SupportModuleControllerService,
} from '@/__generated__/CommonApi';
import Button from '@/components/Button';
import PopupModal from '@/components/PopupModal';
import Section from '@/components/Section';
import { NotiBox } from '@/components/Text/TextNoti';
import InicisMobileOnceForm, {
  InicisMobileOnceRef,
} from '@/components/ThirdParty/InicisMobileOnceForm';
import InicisPcOnceForm, {
  InicisPcOnceRef,
} from '@/components/ThirdParty/InicisPcOnceForm';
import MobiliansOnceForm, {
  MobiliansOnceRef,
} from '@/components/ThirdParty/MobiliansOnceForm';
import NaverPayForm, {
  NaverPayRef,
} from '@/components/ThirdParty/NaverPayForm';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { isMobileDevice } from '@/helpers/BrowserHelper';
import GtmHelper from '@/helpers/GtmHelper';
import {
  INICIS_PAY_METHOD_MOBILE_MAP,
  INICIS_PAY_METHOD_PC_MAP,
  PAYTYPE_ONCE_BANKING,
  PAYTYPE_ONCE_CARD,
  PAYTYPE_ONCE_NAVERPAY,
  PAYTYPE_ONCE_PHONE,
  PAYTYPE_REGULAR_AUTO_BANKING,
  PAYTYPE_REGULAR_CARD,
  PAYTYPE_REGULAR_NAVERPAY,
} from '@/helpers/PaymentHelper';
import {
  getSupportProductName,
  useSupportCategoriesForPayment,
  ENUM_SUPPORTER_TYPE,
  MEMBER_TYPE_FLATMAP,
  MEMBER_TYPE_INDIVIDUAL,
  SUPPORT_TERMS,
  SUPPORT_TERM_ONCE,
  SUPPORT_TERM_REGULAR,
} from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import DonateStep1, {
  SupportInitialValues,
  SupportStep1Data,
} from '@/page-blocks/donate/DonateStep1';
import DonateStep2, {
  SupportStep2Data,
} from '@/page-blocks/donate/DonateStep2';
import DonateStep3, {
  SupportStep3Data,
} from '@/page-blocks/donate/DonateStep3';
import LocalStorage from '@/services/LocalStorage';
import SessionStorage, {
  SESSION_STORAGE_KEY__DONATE_PRIVACY,
} from '@/services/SessionStorage';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import axios from 'axios';
import dayjs from 'dayjs';
import { navigate, PageProps } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import BeforeUnloadComponent from 'react-beforeunload-component';
import styled, { css } from 'styled-components';
import {
  getDonateEncKey,
  resetDonateEncKey,
} from '@/components/ThirdParty/SirenNameCheck';
import { throttle } from 'lodash';

const SectionDonate = styled(Section)`
  padding: 0;
  padding-bottom: 63px;
`;

const Container = styled.div<{
  sticky: boolean;
}>`
  max-width: 544px;
  margin: 0 auto;
  ${({ sticky }) => sticky && 'padding-top: 66px;'}

  ${breakpoint(640)} {
    width: 100%;
    padding: 0 20px;
    ${({ sticky }) => sticky && 'padding-top: 62px;'}
  }
`;
const StepHead = styled.div`
  line-height: 1.2;
  padding: 29px 0;
  color: #b7b8ba;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  &.agree-head {
    margin-top: 46px;
    border-top: 1px solid #e5e6e8;
    border-bottom: 1px solid #b7b8ba;
    padding-bottom: 16px;
  }
`;
const StepBody = styled.div`
  display: none;
`;
const SupportStepSticky = css`
  position: fixed;
  top: 120px;
  background: #fff;
  z-index: 10;
  ${breakpoint(640)} {
    top: 56px;
    width: calc(100% - 40px);
  }
`;
const SupportStep = styled.ol<{
  sticky: boolean;
}>`
  width: 100%;
  max-width: 544px;
  display: flex;
  padding-top: 24px;
  ${({ sticky }) => sticky && SupportStepSticky}
`;
const StepList = styled.li<{ active?: boolean }>`
  flex: 1;
  text-align: center;
  color: #777779;
  padding-bottom: 14px;
  border-bottom: 2px solid #dbdbdb;
  ${({ active }) => {
    if (active) {
      return `
        color: #1CABE2;
        font-weight: 700;
        border-bottom: 4px solid #1CABE2;
      `;
    }
    return ``;
  }}
`;
const ToggleBoxActive = css`
  h2 {
    color: #2d2926;
  }
  form {
    display: block;
  }
  border-bottom: none;
  ${StepBody} {
    display: block;
  }
`;
const ToggleBox = styled.div<{ active: boolean; hidden: boolean }>`
  h2 {
    color: #b7b8ba;
  }
  form {
    display: none;
  }
  border-bottom: 1px solid #b7b8ba;
  ${StepBody} {
    display: none;
  }
  ${NotiBox} {
    margin-bottom: 24px;
  }
  ${({ active }) => active && ToggleBoxActive};
  ${({ hidden }) => hidden && `display: none;`};
`;
const FormResult = styled.div`
  color: #1cabe2;
  width: 100%;
  margin-top: 5px;
`;
const FormModify = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  line-height: 1;
  text-decoration: underline !important;
  color: #828385;
  font-size: 16px;
`;

type SubmitParams = Parameters<
  typeof PaymentControllerService.paymentInfoSetUsingPost
>[0];

// 후원모듈에서 넘어올시 가져와지는 데이터
export interface SupportModuleInputData extends SupportInitialValues {
  moduleCode: string;
  solicitCode: string;
  moduleInfo: {
    optSupportAlert: string;
  };
  campaignName?: string;
}

const DonatePage: FC<PageProps> = observer(({ location }) => {
  const userStore = useUserStore();
  const popupStore = usePopupStore();
  const [currentStep, setCurrentStep] = useState(1);
  const [step1Data, setStep1Data] = useState<SupportStep1Data>();
  const [step2Data, setStep2Data] = useState<SupportStep2Data>();
  const [regularPayInfo, setRegularPayInfo] = useState<RegularPayInfoVo>();
  const totalData = useMemo(() => ({ ...step1Data, ...step2Data }), [
    step1Data,
    step2Data,
  ]);
  const queryParams = useMemo(
    () => ({
      ...Object.fromEntries(new URLSearchParams(location.search)),
      // 같이 하는 기념기부
      ...location.state?.anniProjectData,
    }),
    [location.search, location.state],
  );
  const inicisPcRef = useRef<InicisPcOnceRef>(null);
  const inicisMobileRef = useRef<InicisMobileOnceRef>(null);
  const naverRef = useRef<NaverPayRef>(null);
  const mobiliansRef = useRef<MobiliansOnceRef>(null);
  // 최종 전송 가능한 상태인지 여부
  const [isDataValid, setIsDataValid] = useState(false);
  // 후원 카테고리 관련
  const supportCategories = useSupportCategoriesForPayment();
  const selectedCategory = useMemo(
    () =>
      step1Data
        ? supportCategories.find(
            (category) =>
              category.indexNumber === Number(step1Data.supportCategoryIndex),
          )
        : null,
    [step1Data, supportCategories],
  );
  const [moduleData, setModuleData] = useState<SupportModuleInputData>();
  const [moduleLoaded, setModuleLoaded] = useState(false);
  // 캠페인명
  const [campaignName, setCampaignName] = useState();

  // 총 후원금액
  const finalAmount = useMemo(() => {
    const { supportAmount, supportAmountInput } = totalData;
    return Number(supportAmount || supportAmountInput || 0);
  }, [totalData]);

  // 현금영수증 신청 여부
  const isRequestReceipt = (param: any) => {
    if (
      param?.supporterType === ENUM_SUPPORTER_TYPE.person &&
      param?.agreeReceipt === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Step1 요약 텍스트
  const step1SummaryText = useMemo(() => {
    if (step1Data) {
      const { supportTerm } = step1Data;

      return [
        selectedCategory?.codeName,
        finalAmount.toLocaleString(),
        SUPPORT_TERMS[supportTerm],
      ].join(` / `);
    }
    return null;
  }, [finalAmount, step1Data, selectedCategory]);

  // Step2 요약 텍스트
  const step2SummaryText = useMemo(() => {
    if (step2Data) {
      const {
        supporterType,
        memberTypeCode,
        memberName,
        birthday,
        businessNumber,
      } = step2Data;
      const memberType =
        supporterType === ENUM_SUPPORTER_TYPE.person
          ? MEMBER_TYPE_INDIVIDUAL
          : memberTypeCode;
      return (
        [
          MEMBER_TYPE_FLATMAP[memberType],
          memberName,
          supporterType === ENUM_SUPPORTER_TYPE.person
            ? birthday
            : businessNumber,
        ]
          // 단체의 경우, 사업자번호가 없기 때문에 비워둠
          .filter((v) => v)
          .join(` / `)
      );
    }
    return null;
  }, [step2Data]);

  // step3 완료시
  const onSubmit = useCallback(
    async (step3Data: SupportStep3Data) => {
      // beforeunload 해제
      setIsDataValid(true);
      const finalData = { ...totalData, ...step3Data };
      const finalSupportCategory = supportCategories.find(
        (category) =>
          category.indexNumber === Number(finalData.supportCategoryIndex),
      );

      const encKey = getDonateEncKey();
      const finalParams: SubmitParams = {
        // Step1
        supportTypeCode:
          finalData.supportTerm === SUPPORT_TERM_ONCE
            ? SUPPORT_TERM_ONCE
            : SUPPORT_TERM_REGULAR,
        donationCode: finalSupportCategory?.code || ``,
        solicitCode:
          queryParams.solicitcode ||
          moduleData?.solicitCode ||
          finalSupportCategory?.solicitCode ||
          ``,
        totalAmount: finalAmount,

        // Step2
        memberTypeCode:
          finalData.supporterType === ENUM_SUPPORTER_TYPE.person
            ? MEMBER_TYPE_INDIVIDUAL
            : finalData.memberTypeCode || ``,
        memberName: finalData.memberName,
        birthday: finalData.birthday,
        agreeReceiptYn: finalData.agreeReceipt ? `Y` : `N`,
        // 개인/단체 여부에 따라 가져오는 필드 분기
        juminNumber:
          finalData.supporterType === ENUM_SUPPORTER_TYPE.person
            ? finalData.agreeReceipt === true
              ? // 240627 - 보안점검 :주민등록번호 평문 > 암호화 데이터로 교체
                // (finalData.juminNumber1 || '') + (finalData.juminNumber2 || '')
                SessionStorage.getItem(SESSION_STORAGE_KEY__DONATE_PRIVACY) ||
                ''
              : ''
            : finalData.businessNumber || '',
        mobilePhoneNumber:
          finalData.supporterType === ENUM_SUPPORTER_TYPE.person
            ? finalData.mobilePhoneNumber
            : finalData.contact,
        email:
          finalData.supporterType === ENUM_SUPPORTER_TYPE.person
            ? finalData.email
            : finalData.managerEmail,
        businessNumber: finalData.businessNumber,
        managerName: finalData.managerName,
        managerEmail: finalData.managerEmail,
        mobileAgreeYn: finalData.mobileAgree ? `Y` : `N`,
        emailAgreeYn: finalData.emailAgree ? `Y` : `N`,
        postAgreeYn: finalData.postAgree ? `Y` : `N`,
        zipCode: finalData.zipCode || ``,
        address: finalData.address || ``,
        addressDetail: finalData.addressDetail,
        relationType: finalData.relationType,
        relationName: finalData.relationName,
        relationTelNumber: finalData.relationTelNumber,
        // 기념일 정보 추가
        anniType: finalData.anniType,
        anniDate: finalData.anniDate?.replace(/\./gi, ``),
        story: finalData.story,

        // Step3
        paymentTypeCode: finalData.paymentType,
        acsType: finalData.paymentBankingAgreeType,
        signBinary: finalData.signBinary,
        nmcomp: finalData.bankingOwnerName,
        depositorBirthday:
          finalData.paymentType === PAYTYPE_REGULAR_CARD
            ? finalData.cardOwnerBirthday
            : finalData.bankingOwnerBirthday,
        relation: finalData.bankingOwnerRelation,
        depositorMobilePhoneNumber: finalData.bankingOwnerPhone,
        banksett: finalData.paymentBankCode,
        noacct: finalData.bankingNumber,
        paymentDay: finalData.paymentDay,
        cardNumber:
          finalData.paymentType === PAYTYPE_REGULAR_CARD
            ? finalData.cardNumber1 +
              finalData.cardNumber2 +
              finalData.cardNumber3 +
              finalData.cardNumber4
            : ``,
        cardExpire:
          finalData.paymentType === PAYTYPE_REGULAR_CARD
            ? finalData.cardExpireY + finalData.cardExpireM
            : ``,

        // etc
        productName: getSupportProductName(finalSupportCategory),
        deviceType: isMobileDevice() ? `MOBILE` : `PC`,
        nationCode: `KR`,
        pathType: `SUPPORT`,
        moduleCode: moduleData?.moduleCode ? moduleData.moduleCode : `EMPTY`,
        successUrl: `${process.env.SELF_URL}/donate/complete`,
        // trackcode 저장
        trackcode: SessionStorage.getItem(`trackcode`) || undefined,
        trk_id: SessionStorage.getItem(`trk_id`) || undefined,

        // 기업회원 누락으로 추가
        contact: finalData.contact,

        // 같이 하는 기념기부
        aprjtIdx: queryParams.aprjtIdx,
        openNameYn: queryParams.openNameYn,
        openAmountYn: queryParams.openAmountYn,

        // 개인정보 암호화
        encCert: isRequestReceipt(finalData) === true && encKey?.cert,
        publicKey: isRequestReceipt(finalData) === true && encKey?.key,
        /*
        isSecureContext:
          isRequestReceipt(finalData) === true && encKey?.isSecureContext,
        */
        secureContextYn:
          isRequestReceipt(finalData) === true && encKey?.isSecureContext
            ? 'Y'
            : 'N',
      };

      const currentSupportCategory = supportCategories.find(
        (category) =>
          category.indexNumber === Number(step1Data?.supportCategoryIndex),
      );

      // 주문정보
      const orderData = {
        campaignName:
          campaignName ||
          (Object.keys(queryParams).length === 0 ? 'direct' : 'none'),
        campaignPagenum:
          moduleData?.solicitCode || queryParams.solicitcode || '',
        promotionName:
          campaignName ||
          (Object.keys(queryParams).length === 0 ? 'direct' : 'none'),
        promotionId: moduleData?.solicitCode || queryParams.solicitcode || '',
        value: finalData?.supportAmount || finalData?.supportAmountInput || '0',
        items: [
          {
            itemId: currentSupportCategory?.code,
            itemName: currentSupportCategory?.codeName,
            itemCategory: currentSupportCategory?.categoryCode,
            itemCategory2:
              finalData.birthday &&
              dayjs(finalData.birthday, `YYYYMMDD`, true).isValid()
                ? `${finalData.birthday.slice(0, 4)}`
                : '',
            itemCategory3:
              (finalData.juminNumber &&
                finalData.juminNumber.length === 13 &&
                (finalData.juminNumber.slice(6, 7) === '1' ||
                finalData.juminNumber.slice(6, 7) === '3'
                  ? 'M'
                  : 'F')) ||
              '',
            itemListName: '',
            quantity: '1',
            price:
              finalData?.supportAmount || finalData?.supportAmountInput || '0',
          },
        ],
        paymentType: finalParams?.paymentTypeCode,
      };
      // gtm addPaymentInfo
      GtmHelper.addPaymentInfo(orderData);

      // 폼데이터로 변환
      // const formData = new FormData();
      // Object.keys(finalParams).forEach(
      //   (key) =>
      //     finalParams[key] !== undefined &&
      //     formData.append(key, finalParams[key]),
      // );

      const formData = finalParams;

      // 전송 먼저
      // swagger에는 파라미터가 쿼리스트링으로 되어있어서 maxLength 오류남. FormData로 전송을 위해 axios 사용.
      try {
        const {
          data: {
            resultCode,
            resultMessage,
            orderNumber,
            mkey,
            signature,
            timestamp,
            reserveId,
            orderToken,
            productCode,
            productName,
            totalPayAmount,
            returnUrl,
            actionType,
            targetRecurrentId,
          },
        } = await axios.post<PaymentInfoSetVo>(
          `${process.env.BACKEND_URL}/unicef/api/fo/common/payment/paymentInfoSet`,
          formData,
          {
            headers: {
              'X-AUTH-TOKEN': LocalStorage.getToken(),
            },
            data: formData,
          },
        );

        // 주문번호 생성에 성공하면
        if (resultCode === `success`) {
          // 주문정보 저장
          SessionStorage.setItem(`orderData`, JSON.stringify(orderData));
          // 주문토큰 저장
          SessionStorage.setItem(`orderToken`, orderToken);
          // trackcode, trk_id 비우기
          SessionStorage.removeItem(`trackcode`);
          SessionStorage.removeItem(`trk_id`);
          // 개인정보 암호화키 초기화 (완료)
          resetDonateEncKey();
          let token = sessionStorage.getItem(`or.kr.unicef.path.token`) as any;

          // 일시후원
          if (finalData.supportTerm === SUPPORT_TERM_ONCE) {
            // 이니시스
            if (
              [PAYTYPE_ONCE_CARD, PAYTYPE_ONCE_BANKING].includes(
                finalData.paymentType,
              )
            ) {
              // 모바일이면
              if (isMobileDevice()) {
                if (!orderNumber) {
                  popupStore.show(`주요정보가 넘어오지 않았습니다`);
                  return;
                }
                inicisMobileRef.current?.init({
                  payMethod:
                    INICIS_PAY_METHOD_MOBILE_MAP[finalData.paymentType],
                  goods: finalParams.productName.replace(/&/gi, `_`),
                  uname: finalParams.memberName.replace(/&/gi, `_`),
                  amt: finalParams.totalAmount,
                  oid: orderNumber,
                  moduleYn: token,
                });
              } else {
                if (!orderNumber || !mkey || !signature || !timestamp) {
                  popupStore.show(`주요정보가 넘어오지 않았습니다`);
                  return;
                }
                inicisPcRef.current?.init({
                  payMethod: INICIS_PAY_METHOD_PC_MAP[finalData.paymentType],
                  goodname: finalParams.productName,
                  buyername: finalParams.memberName,
                  buyeremail:
                    finalData.supporterType === ENUM_SUPPORTER_TYPE.group
                      ? finalData.managerEmail
                      : finalParams.email,
                  buyertel:
                    finalData.supporterType === ENUM_SUPPORTER_TYPE.group
                      ? finalData.contact
                      : finalData.mobilePhoneNumber || ``,
                  price: finalParams.totalAmount,
                  mKey: mkey,
                  oid: orderNumber,
                  signature,
                  timestamp,
                  moduleYn: token,
                });
              }
            }
            // 네이버 결제
            else if (finalData.paymentType === PAYTYPE_ONCE_NAVERPAY) {
              if (!orderNumber) {
                popupStore.show(`주요정보가 넘어오지 않았습니다`);
                return;
              }
              naverRef.current?.payNormal({
                productCount: 1,
                productName: finalParams.productName,
                totalPayAmount: finalParams.totalAmount,
                taxScopeAmount: 0,
                taxExScopeAmount: finalParams.totalAmount,
                merchantPayKey: orderNumber,
                merchantUserKey: finalParams.memberName.replaceAll(/\s/g, '_'),
              });
            }
            // 모빌리언스 휴대폰 결제
            else if (finalData.paymentType === PAYTYPE_ONCE_PHONE) {
              mobiliansRef.current?.init({
                goodname: finalParams.productName,
                price: finalParams.totalAmount,
                oid: orderNumber,
              });
            }
          }
          // 정기후원
          else if (finalData.supportTerm === SUPPORT_TERM_REGULAR) {
            // 네이버페이
            if (finalData.paymentType === PAYTYPE_REGULAR_NAVERPAY) {
              if (!reserveId) {
                popupStore.show(`주요정보가 넘어오지 않았습니다`);
                return;
              }

              naverRef.current?.payRecurrent({
                actionType,
                targetRecurrentId,
                productCode,
                productName,
                totalPayAmount,
                returnUrl: `${process.env.BACKEND_URL}/unicef/api/fo/common/payment/naverRegularReturn?orderNumber=${orderNumber}`,
              });
            }
            // 자동이체, 카드 (서버에서 전부 처리)
            else if (
              [PAYTYPE_REGULAR_AUTO_BANKING, PAYTYPE_REGULAR_CARD].includes(
                finalData.paymentType,
              )
            ) {
              // 바로 이동
              navigate(`/donate/complete?orderNumber=${orderNumber}`);
            }
          }
        }
        // 주문번호 생성에 실패하면
        else if (resultMessage) {
          popupStore.show(resultMessage);
        }
      } catch (e) {
        if (e.response) {
          popupStore.show(e.response.data.resultMessage);
          console.error(e);
        }
      }
    },
    [
      totalData,
      supportCategories,
      queryParams,
      moduleData?.solicitCode,
      moduleData?.moduleCode,
      finalAmount,
      campaignName,
      step1Data?.supportCategoryIndex,
      popupStore,
    ],
  );
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const onStep1Submit = useCallback(
    (data: SupportStep1Data) => {
      const currentSupportCategory = supportCategories.find(
        (category) =>
          category.indexNumber === Number(data.supportCategoryIndex),
      );
      // 캠페인에서 넘어올 시 캠페인명 로드까지 완료 후 gtm 전송
      if (currentSupportCategory && moduleLoaded) {
        // gtm beginCheckout
        GtmHelper.beginCheckout({
          campaignName:
            campaignName ||
            (Object.keys(queryParams).length === 0 ? 'direct' : 'none'),
          campaignPagenum:
            moduleData?.solicitCode || queryParams.solicitcode || '',
          promotionName:
            campaignName ||
            (Object.keys(queryParams).length === 0 ? 'direct' : 'none'),
          promotionId: moduleData?.solicitCode || queryParams.solicitcode || '',
          value: data.supportAmount || data.supportAmountInput || '0',
          items: [
            {
              itemId: currentSupportCategory.code,
              itemName: currentSupportCategory.codeName,
              itemCategory: currentSupportCategory.categoryCode,
              itemCategory2: '',
              itemCategory3: '',
              itemListName: '',
              quantity: '1',
              price: data.supportAmount || data.supportAmountInput || '0',
            },
          ],
        });
      }

      setStep1Data(data);
      setCurrentStep(2);
      scrollToTop();
    },
    [
      campaignName,
      moduleData?.solicitCode,
      moduleLoaded,
      queryParams,
      supportCategories,
    ],
  );

  const onStep2Submit = useCallback(
    (data: SupportStep2Data) => {
      const currentSupportCategory = supportCategories.find(
        (category) =>
          category.indexNumber === Number(step1Data?.supportCategoryIndex),
      );
      // gtm addDonorInfo
      GtmHelper.addDonorInfo({
        campaignName:
          campaignName ||
          (Object.keys(queryParams).length === 0 ? 'direct' : 'none'),
        campaignPagenum:
          moduleData?.solicitCode || queryParams.solicitcode || '',
        promotionName:
          campaignName ||
          (Object.keys(queryParams).length === 0 ? 'direct' : 'none'),
        promotionId: moduleData?.solicitCode || queryParams.solicitcode || '',
        value: step1Data?.supportAmount || step1Data?.supportAmountInput || '0',
        items: [
          {
            itemId: currentSupportCategory?.code,
            itemName: currentSupportCategory?.codeName,
            itemCategory: currentSupportCategory?.categoryCode,
            itemCategory2:
              data.birthday &&
              dayjs(`${data.birthday}`, `YYYYMMDD`, true).isValid()
                ? `${data.birthday.slice(0, 4)}`
                : ``,
            itemCategory3:
              (data.juminNumber &&
                data.juminNumber.length === 13 &&
                (data.juminNumber.slice(6, 7) === '1' ||
                data.juminNumber.slice(6, 7) === '3'
                  ? 'M'
                  : 'F')) ||
              '',
            itemListName: '',
            quantity: '1',
            price:
              step1Data?.supportAmount || step1Data?.supportAmountInput || '0',
          },
        ],
      });

      setStep2Data(data);
      setCurrentStep(3);
      scrollToTop();
    },
    [
      campaignName,
      moduleData?.solicitCode,
      queryParams,
      step1Data?.supportAmount,
      step1Data?.supportAmountInput,
      step1Data?.supportCategoryIndex,
      supportCategories,
    ],
  );

  // 후원모듈 정보 가져오기 (로컬호스트에선 작동 안됨 - 파라미터를 쿠키로 관리)
  const loadSupportModuleData = useCallback(async () => {
    try {
      const {
        data,
        resultCode,
      } = await SupportModuleControllerService.getSupportModuleInputInfoUsingGet();
      if (resultCode === `success`) {
        setModuleData(data);

        // 후원 category_name 불러오기
        try {
          const {
            data: _data,
          } = await SupportModuleControllerService.getCampaignNameUsingGet({
            solicitCode: data?.solicitCode,
          });
          setCampaignName(_data?.campaignName);
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {}
    // 모듈 로드 완료
    setModuleLoaded(true);
  }, []);

  const [isSticky, setSticky] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = throttle(() => {
    if (containerRef.current) {
      const { top } = containerRef.current.getBoundingClientRect();
      const isMobile = window.innerWidth <= 640;
      setSticky(isMobile ? top <= 56 : top <= 120);
    }
  }, 20);

  // data handler
  useEffect(() => {
    loadSupportModuleData();
    // 개인정보 암호화키 초기화 (진입)
    resetDonateEncKey();
  }, [loadSupportModuleData]);

  // scroll handler (data handler랑 병합 금지!!!)
  useEffect(() => {
    // 후원 단계 스크롤시 위에 고정
    window.addEventListener(`scroll`, handleScroll);
    return () => {
      window.removeEventListener(`scroll`, handleScroll);
    };
  }, [handleScroll]);

  // 사용자정보, 모듈정보 로딩후 페이지 시작
  if (!userStore.loaded || !moduleLoaded) {
    return null;
  }

  return (
    <LayoutWithTitle location={location} title="후원하기" hideGnb>
      {/* 주의 : 최신 브라우저에서는 커스텀 메세지 출력 안됨 */}
      {/* https://stackoverflow.com/a/38880926 */}
      <BeforeUnloadComponent
        blockRoute={!isDataValid}
        modalComponentHandler={({ handleModalLeave, handleModalCancel }) => (
          <PopupModal
            onRequestClose={handleModalCancel}
            message="조금만 더 입력하시면 후원자님의 소중한 나눔이 어린이에게 전달될 수 있습니다."
            footer={
              <>
                <Button size="sm" outline onClick={handleModalLeave}>
                  취소
                </Button>
                <Button size="sm" onClick={handleModalCancel}>
                  계속
                </Button>
              </>
            }
          />
        )}
      >
        <SectionDonate>
          <Container ref={containerRef} sticky={isSticky}>
            <SupportStep sticky={isSticky}>
              <StepList active={currentStep === 1}>1.후원방법</StepList>
              <StepList active={currentStep === 2}>2.후원자 정보</StepList>
              <StepList active={currentStep === 3}>3.결제 정보</StepList>
            </SupportStep>

            <ToggleBox active={currentStep === 1} hidden={currentStep < 1}>
              <StepHead>
                <h2>
                  <Tit size="s3">1. 후원 방법</Tit>
                </h2>
                {!moduleData && step1Data && currentStep !== 1 && (
                  <FormModify type="button" onClick={() => setCurrentStep(1)}>
                    변경{` `}
                  </FormModify>
                )}
                <FormResult>{step1SummaryText}</FormResult>
              </StepHead>
              <StepBody>
                <DonateStep1
                  initialValues={
                    moduleData || {
                      ...queryParams,
                      // 소문자로 통일하기로 함ㅠㅠ
                      solicitCode: queryParams.solicitcode,
                    }
                  }
                  onSubmit={onStep1Submit}
                />
              </StepBody>
            </ToggleBox>
            <ToggleBox active={currentStep === 2} hidden={currentStep < 2}>
              <StepHead>
                <h2>
                  <Tit size="s3">2. 후원자 정보</Tit>
                </h2>
                {step2Data && currentStep !== 2 && (
                  <FormModify type="button" onClick={() => setCurrentStep(2)}>
                    변경{` `}
                  </FormModify>
                )}
                <FormResult>{step2SummaryText}</FormResult>
              </StepHead>
              <StepBody>
                <DonateStep2
                  queryParams={queryParams}
                  step1Data={step1Data}
                  onSubmit={onStep2Submit}
                  selectedCategory={selectedCategory}
                  onLoadRegularPayInfo={(payInfo) => setRegularPayInfo(payInfo)}
                />
              </StepBody>
            </ToggleBox>
            <ToggleBox active={currentStep === 3} hidden={currentStep < 3}>
              <StepHead>
                <h2>
                  <Tit size="s3">3. 결제 정보</Tit>
                </h2>
              </StepHead>
              {/* 폼이 미리 생성되어있고, display: none일때 폼 데이터 세팅이 안되는 문제가 있어서 조건부 렌더링으로 수정 */}
              {currentStep === 3 && (
                <StepBody>
                  <DonateStep3
                    queryParams={queryParams}
                    onSubmit={onSubmit}
                    totalData={totalData}
                    currentRegularPayInfo={regularPayInfo}
                    moduleData={moduleData}
                  />
                </StepBody>
              )}
            </ToggleBox>
          </Container>
        </SectionDonate>
      </BeforeUnloadComponent>
      <InicisPcOnceForm ref={inicisPcRef} />
      <InicisMobileOnceForm ref={inicisMobileRef} />
      <NaverPayForm ref={naverRef} />
      <MobiliansOnceForm ref={mobiliansRef} />
    </LayoutWithTitle>
  );
});

export default DonatePage;
