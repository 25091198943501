import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import BannerImg from '@/assets/img/for-every-child/onmymind_banner.png';
import BannerImgM from '@/assets/img/for-every-child/onmymind_bannerM@2x.png';
import BttImg from '@/assets/img/for-every-child/onmymind_bn_img@2x.png';
import BttImgM from '@/assets/img/for-every-child/onmymind_bn_imgM@2x.png';
import Obj1 from '@/assets/img/for-every-child/onmymind_obj1@2x.png';
import Obj2 from '@/assets/img/for-every-child/onmymind_obj2@2x.png';
import Obj3 from '@/assets/img/for-every-child/onmymind_obj3@2x.png';
import Obj4 from '@/assets/img/for-every-child/onmymind_obj4@2x.png';
import WedoImg from '@/assets/img/for-every-child/endviolence_wedo_img@2x.png';
import WedoImgM from '@/assets/img/for-every-child/endviolence_wedo_imgM@2x.png';
import Button from '@/components/Button';
import { CampaignData } from '@/components/Card/CampaignCard2';
import Container from '@/components/Container';
import Row from '@/components/Grid/Row';
import Image from '@/components/Image';
import { SectionTop } from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps, Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import LinkSafe from '@/components/LinkSafe';
import RelativeBoardCard, {
  NewsData,
} from '@/components/Card/RelativeBoardCard';
import ButtonMoreView from '@/components/ButtonMoreView';

const itemData = [
  {
    id: 1,
    img: Obj1,
    desc: [
      {
        dec: `일상에서 자주 우울감을 느끼는 어린이 비율 1/5`,
      },
    ],
  },
  {
    id: 2,
    img: Obj2,
    desc: [
      {
        dec: `매 11분마다 1명의 어린이가 스스로 삶을 포기`,
      },
    ],
  },
  {
    id: 3,
    img: Obj3,
    desc: [
      {
        dec: `24세가 되기 전 마음건강 문제가 생긴 청소년 비율 75%`,
      },
    ],
  },
  {
    id: 4,
    img: Obj4,
    desc: [
      {
        dec: `전 세계적으로 마음건강 문제가 있는 부모와 같이 사는 어린이 비율 1/4`,
      },
    ],
  },
];

const SectionBanner = styled(Section)`
  padding-top: 90px;

  ${breakpoint(`mobile`)} {
    padding-top: 60px;
  }
`;

const SectionSort = styled(Section)`
  padding-bottom: 0 !important;
  .process-ol {
    justify-content: center;
    .list-item {
      width: 33.33%;
      margin-bottom: 124px;

      &:nth-child(1),
      &:nth-child(2) {
        width: 50%;
      }

      .dec-ul {
        max-width: 210px;
        margin: 0 auto;
        margin-top: 25px;

        strong {
          color: #1cabe2;
          font-weight: 400;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      .list-item {
        margin-bottom: 70px;
        dt {
          padding: 0;
          .img-wrap {
            max-width: 80px;
          }
        }
        .dec-ul {
          max-width: 120px;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      .list-item {
        width: 50%;

        &:nth-child(1) {
          width: 100%;
        }
      }
    }
  }
`;

const CardFlex = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  align-items: stretch;

  & > li {
    width: 33.33%;
    padding: 0 16px;

    .card {
      height: 100%;
      position: relative;
      background: #fff;
      box-shadow: 24px 14px 40px 0 rgba(0, 0, 0, 0.08);
      border-radius: 0 0 20px 0;
      padding: 40px 32px;
      padding-top: 72px;

      dt {
        display: inline-block;
        background: #1cabe2;
        border-radius: 0 0 16px 0;
        line-height: 56px;
        color: #fff;
        padding: 0 32px;
        position: absolute;
        left: 0;
        top: -16px;
      }

      dd {
        height: 100%;
        .dec {
          height: calc(100% - 3.75rem);
        }
        ${ButtonMoreView} {
          margin: 1rem 0 0 0;
          width: 100%;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      width: 100%;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      .card {
        dt {
          line-height: 44px;
          padding: 0 20px;
        }
        dd {
          height: auto;
          .dec {
            height: auto;
          }
        }
      }
    }
  }
`;
const SectionInfluence = styled(Section)``;

const SectionWedo = styled(Section)`
  ${SectionHeader} {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 316px;
      height: 316px;
      border-radius: 100%;
      background: #1cabe2;
      position: absolute;
      left: -116px;
      top: -134px;
      z-index: -1;
    }

    ${breakpoint(1160)} {
      &::before {
        width: 27.24vw;
        height: 27.24vw;
        left: -10vw;
        top: -11.55vw;
      }
    }
    ${breakpoint(`tablet`)} {
      &::before {
        width: 200px;
        height: 200px;
        left: -77px;
        top: -89px;
      }
    }
  }
  .flex-wrap {
    padding-right: 48.6%;
    position: relative;
    flex-wrap: wrap;

    .blit-list {
      width: 100%;
      font-weight: 700;
      & > li {
        margin-bottom: 16px;
        line-height: 1.7;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .col-img {
      width: 48.6%;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    ${breakpoint(`tablet`)} {
      padding-right: 0;

      .col-img {
        width: 100%;
        position: relative;
        margin-top: 8px;
      }

      .blit-list {
        & > li {
          margin-bottom: 8px;
        }
      }
    }
  }
`;

const SectionCampaign = styled(Section)`
  padding-bottom: 234px !important;

  ${breakpoint(`tablet`)} {
    padding-bottom: 120px !important;
  }
`;

const SectionBtt = styled(Section)`
  background: #1cabe2;
  padding-top: 46px;
  header {
    ${Tit} {
      line-height: 1.5;
    }
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 72px;
    position: relative;

    .col-dec {
      width: 59%;
      padding-right: 20px;

      .header-dec {
        color: #fff;
        margin-top: 32px;
        line-height: 1.7;
      }
      button {
        margin-top: 64px;
        max-width: 256px;
        width: 100%;
      }
    }

    .col-img {
      position: absolute;
      bottom: 0;
      right: -261px;
      width: 683px;
    }
  }
  ${breakpoint(1160)} {
    .flex {
      .col-img {
        right: -22.5vw;
        width: 58.88vw;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .flex {
      padding-bottom: 0;
      .col-dec {
        width: 100%;
        padding-right: 0;
        position: relative;
        z-index: 1;
        .header-dec {
          line-height: 2;
        }
        button {
          max-width: 152px;
        }
      }
      .col-img {
        position: relative;
        text-align: right;
        right: -20px;
        margin-top: -46px;
        width: 100%;
      }
    }
  }
`;

const OnMyMind: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<CampaignData[]>([]);
  const [newsData, setNewsData] = useState<NewsData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['EV01'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          title: story.subject,
          dec: story.contents,
          image: story.imgAttGrpNoTn,
          pop: `#Love Myself 캠페인 활동`,
          youtube: story.optData1,
          thumYoutube: story.optData2,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadNews = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290', '300', '295'],
        pagePerCount: 8,
        category: ['A035'],
      });
      const articles = data as any;
      setNewsData(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
  }, [loadSlideData]);

  useEffect(() => {
    loadNews();
  }, [loadNews]);

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="#OnMyMind"
      description="for every child, mental health"
    >
      <SectionTop
        css={`
          padding-top: 32px;
        `}
      >
        <Container>
          <Row
            justifyContent="flex-end"
            css={`
              margin-bottom: 24px;
            `}
          >
            <Button
              css={`
                padding: 0 30px;
                height: 40px;
              `}
              size="sm"
              onClick={() =>
                navigate(`/what-we-do/for-every-child/onmymind/en`)
              }
            >
              EN
            </Button>
          </Row>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  BTS는 #OnMyMind를 <br />
                  지지합니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                2017년 11월 방탄소년단(BTS)과 빅히트 뮤직은 유니세프한국위원회와
                공식 파트너십을 체결하고, ‘자신을 사랑하자’는 ‘LOVE MYSELF’
                캠페인을 통해 폭력으로 고통받는 어린이와 청소년들이 스스로를
                사랑하고 신뢰하며 상처를 극복할 수 있도록 #ENDviolence 캠페인을
                후원해왔습니다. <br />
                #OnMyMind 캠페인은 어린이·청소년이 처한 다양한 종류의 폭력을
                예방하는 것에서 나아가 어린이·청소년들이 부정적인 포함하여
                다양한 감정을 느끼는 것은 자연스러운 것임을 알리고, 알리고,
                자신의 마음건강 상태에 대해 자유롭게 이야기할 수 있도록 독려하기
                위한 캠페인입니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="qV1wiMZkuCk" />
        </PageComponent>
      </SectionTop>

      <Container>
        <SectionBanner>
          <Link
            to="https://lovemyself2.onmymind.unicef.or.kr/"
            className="banner"
            target="_blank"
          >
            <Image
              pcSrc={BannerImg}
              mobileSrc={BannerImgM}
              alt="OnMyMind, UNICEF with BTS"
            />
          </Link>
        </SectionBanner>
      </Container>

      <SectionSort className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">
                마음건강 문제,
                <br />
                얼마나 심각한가요?
              </Tit>
            </h2>
          </SectionHeader>

          <ProcessList itemData={itemData} className="ProcessList" />
        </Container>
      </SectionSort>

      <SectionInfluence className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">
                마음건강에는
                <br />
                이런 것들도 중요해요!
              </Tit>
            </h2>
          </SectionHeader>
          <CardFlex>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s3">보호자의 마음건강</Tit>
                </dt>
                <dd>
                  <p className="dec">
                    가정이 어린이·청소년의 건강한 마음에 1차 백신이 되어줄 수
                    있습니다. 보호자도 스스로 마음건강을 관리하며 행복할 때,
                    아이들도 행복합니다.
                  </p>
                  <ButtonMoreView
                    mode="skyTransparent"
                    onClick={() => navigate(`/what-we-do/news/203621`)}
                  >
                    자세히 보기
                  </ButtonMoreView>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s3">어린이의 마음건강</Tit>
                </dt>
                <dd>
                  <p className="dec">
                    어린이 · 청소년이 자신의 마음을 이해하고, 긍정적 마음건강
                    관리 능력을 키우고, 도움을 요청할 수 있는 마음건강 문해력이
                    필요합니다.
                  </p>
                  <ButtonMoreView
                    mode="skyTransparent"
                    onClick={() => navigate(`/what-we-do/news/200021`)}
                  >
                    자세히 보기
                  </ButtonMoreView>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s3">긴급구호 상황에서의 마음건강</Tit>
                </dt>
                <dd>
                  <p className="dec">
                    천재지변, 빈곤, 기후변화, COVID-19 등의 긴급구호 상황은
                    어린이의 마음건강에 부정적인 결과를 초래할 수 있습니다.
                  </p>
                </dd>
              </dl>
            </li>
          </CardFlex>
        </Container>
      </SectionInfluence>

      <SectionWedo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                #OnMyMind를 통해 <br />
                유니세프가 하는 일
              </Tit>
            </h2>
          </SectionHeader>
          <div className="flex-wrap flex">
            <ul className="blit-list">
              <li>
                마음건강에 대한 대중의 인식을 변화하고, 긍정적 마음건강에 관한
                이해 도모
              </li>
              <li>
                학교에서 마음건강에 관해 배우고, 안전한 환경에서 마음건강
                서비스에 접근할 수 있도록 지원
              </li>
              <li>
                가정 내 긍정적 양육을 장려해 어린이·청소년 스스로 자신의
                마음건강 상태 점검 및 회복력을 높일 수 있도록 역량강화 지원
              </li>
            </ul>
            <div className="col-img">
              <Image pcSrc={WedoImg} mobileSrc={WedoImgM} />
            </div>
          </div>
        </Container>
      </SectionWedo>

      <SectionBtt>
        <Container>
          <header>
            <h2>
              <Tit size="s1" color="white" weight="normal">
                소중한 후원으로 모든 어린이·청소년이
                <br />
                안전하고 행복한 삶을 만들 수 있습니다
              </Tit>
            </h2>
          </header>
          <div className="flex">
            <div className="col-dec">
              <p className="header-dec">
                “마음건강에 관해서는 모든 나라가 개발도상국입니다.”
                <br />- 마음건강 전문가, Shekhar Saxena
              </p>

              <Button
                color="yellow"
                title="OnMyMind-후원하기"
                onClick={() =>
                  navigate(`${process.env.BACKEND_URL}/campaign/onmymind/dn`)
                }
              >
                후원하기
              </Button>
            </div>
            <div className="col-img">
              <Image pcSrc={BttImg} mobileSrc={BttImgM} />
            </div>
          </div>
        </Container>
      </SectionBtt>
      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A035" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {newsData.length > 0 && (
            <RelativeBoardCard
              newslist={newsData}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default OnMyMind;
