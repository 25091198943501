import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import { HonorsClubControllerService } from '@/__generated__/FrontApi';
import People1 from '@/assets/img/img_interview_card_01@2x.png';
import honorsclub1 from '@/assets/img/tmp/honors-club/honorsclub_obj1@2x.png';
import honorsclub2 from '@/assets/img/tmp/honors-club/honorsclub_obj2@2x.png';
import honorsclub3 from '@/assets/img/tmp/honors-club/honorsclub_obj3@2x.png';
import honorsclub4 from '@/assets/img/tmp/honors-club/honorsclub_obj4@2x.png';
import honorsclub5 from '@/assets/img/tmp/honors-club/honorsclub_obj5@2x.png';
import honorsclub6 from '@/assets/img/tmp/honors-club/honorsclub_obj6@2x.png';
import People2 from '@/assets/img/tmp/honors-club/people_img1.png';
import People10 from '@/assets/img/tmp/honors-club/people_img10.png';
import People11 from '@/assets/img/tmp/honors-club/people_img11.png';
import People12 from '@/assets/img/tmp/honors-club/people_img12.png';
import People13 from '@/assets/img/tmp/honors-club/people_img13.png';
import People14 from '@/assets/img/tmp/honors-club/people_img14.png';
import People15 from '@/assets/img/tmp/honors-club/people_img15.png';
import People16 from '@/assets/img/tmp/honors-club/people_img16.png';
import People17 from '@/assets/img/tmp/honors-club/people_img17.png';
import People18 from '@/assets/img/tmp/honors-club/people_img18.png';
import People19 from '@/assets/img/tmp/honors-club/people_img19.png';
import People20 from '@/assets/img/tmp/honors-club/people_img20.png';
import People21 from '@/assets/img/tmp/honors-club/people_img21.png';
import People22 from '@/assets/img/tmp/honors-club/people_img22.png';
import People3 from '@/assets/img/tmp/honors-club/people_img3@2x.png';
import People4 from '@/assets/img/tmp/honors-club/people_img4@2x.png';
import People5 from '@/assets/img/tmp/honors-club/people_img5@2x.png';
import People6 from '@/assets/img/tmp/honors-club/people_img6@2x.png';
import People7 from '@/assets/img/tmp/honors-club/people_img7.png';
import People8 from '@/assets/img/tmp/honors-club/people_img8.png';
import People9 from '@/assets/img/tmp/honors-club/people_img9.png';
import Qualification1 from '@/assets/img/tmp/honors-club/qualification_obj1@2x.png';
import Qualification2 from '@/assets/img/tmp/honors-club/qualification_obj2@2x.png';
import TopImg from '@/assets/img/tmp/honors-club/top_img@2x.png';
import TopImgM from '@/assets/img/tmp/honors-club/top_imgM@2x.png';
import Button from '@/components/Button';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import LabelText from '@/components/Form/LabelText';
import Image from '@/components/Image';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputNumber from '@/components/Input/InputNumber';
import InputText from '@/components/Input/InputText';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { emailRegex, mobileRegex } from '@/helpers/ValidationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { usePopupStore } from '@/stores/PopupStore';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

const peopleData = [
  {
    id: 1,
    img: People1,
    desc: [
      {
        id: 1,
        dec: `유니세프한국위원회 친선대사 <br />배우 안성기`,
      },
    ],
  },
  {
    id: 2,
    img: People10,
    desc: [
      {
        id: 2,
        dec: `유니세프한국위원회 친선대사 <br />배우 김혜수`,
      },
    ],
  },
  {
    id: 3,
    img: People2,
    desc: [
      {
        id: 3,
        dec: `유니세프 국제친선대사 <br />김연아 前국가대표`,
      },
    ],
  },
  {
    id: 4,
    img: People7,
    desc: [
      {
        id: 4,
        dec: `유니세프동아시아태평양지역 친선대사 <br />배우 최시원`,
      },
    ],
  },
  {
    id: 5,
    img: People3,
    desc: [
      {
        id: 5,
        dec: `유니세프한국위원회 친선대사 <br />배우 원빈`,
      },
    ],
  },
  {
    id: 6,
    img: People5,
    desc: [
      {
        id: 6,
        dec: `아모레퍼시픽 서경배 회장`,
      },
    ],
  },
  {
    id: 7,
    img: People12,
    desc: [
      {
        id: 7,
        dec: `배우 설경구`,
      },
    ],
  },
  {
    id: 8,
    img: People14,
    desc: [
      {
        id: 8,
        dec: `배우 송윤아`,
      },
    ],
  },
  {
    id: 9,
    img: People4,
    desc: [
      {
        id: 9,
        dec: `배우 이민호`,
      },
    ],
  },
  {
    id: 10,
    img: People15,
    desc: [
      {
        id: 10,
        dec: `배우 장근석`,
      },
    ],
  },
  {
    id: 11,
    img: People13,
    desc: [
      {
        id: 11,
        dec: `배우 송중기`,
      },
    ],
  },
  {
    id: 12,
    img: People6,
    desc: [
      {
        id: 12,
        dec: `배우 이혜리`,
      },
    ],
  },
  {
    id: 18,
    img: People18,
    desc: [
      {
        id: 18,
        dec: `화가 김병종`,
      },
    ],
  },
  {
    id: 13,
    img: People8,
    desc: [
      {
        id: 13,
        dec: `배우 한지민`,
      },
    ],
  },
  {
    id: 14,
    img: People11,
    desc: [
      {
        id: 14,
        dec: `배우 박서준`,
      },
    ],
  },
  {
    id: 15,
    img: People9,
    desc: [
      {
        id: 15,
        dec: `배우 수지`,
      },
    ],
  },
  {
    id: 16,
    img: People16,
    desc: [
      {
        id: 16,
        dec: `배우 지창욱`,
      },
    ],
  },
  {
    id: 17,
    img: People17,
    desc: [
      {
        id: 17,
        dec: `축구선수 김민재`,
      },
    ],
  },
  {
    id: 19,
    img: People19,
    desc: [
      {
        id: 19,
        dec: `한문학자 故 이대봉`,
      },
    ],
  },
  {
    id: 20,
    img: People20,
    desc: [
      {
        id: 20,
        dec: `가수 NCT 도영`,
      },
    ],
  },
  {
    id: 21,
    img: People21,
    desc: [
      {
        id: 21,
        dec: `동국제강그룹 장세욱 부회장`,
      },
    ],
  },
  {
    id: 22,
    img: People22,
    desc: [
      {
        id: 22,
        dec: `코리아결제시스템(주) 박형석 회장`,
      },
    ],
  },
];

const qualification = [
  {
    id: 1,
    img: Qualification1,
    title: `정회원`,
    desc: [
      {
        id: 11,
        dec: `일시후원 또는 누적 후원금액이 <strong>1억 원 이상</strong> 개인후원자`,
      },
    ],
  },
  {
    id: 2,
    img: Qualification2,
    title: `약정회원`,
    desc: [
      {
        id: 21,
        dec: `5년 내 <strong>1억 원 이상</strong> 후원을 약정한 개인후원자`,
      },
    ],
  },
];

const honorsclub = [
  {
    id: 1,
    img: honorsclub1,
    title: `유니세프 사업 현장 방문`,
    desc: [
      {
        id: 11,
        dec: `내 후원금이 쓰이는 현장을 찾아 직접 어린이들을 만나고, 그로 인한 변화를 눈으로 확인하실 수 있습니다.`,
      },
    ],
  },
  {
    id: 2,
    img: honorsclub3,
    title: `새로운 만남`,
    desc: [
      {
        id: 21,
        dec: `같은 가치를 공유하는 분들과의 만남을 통해 보람과 기쁨을 얻을 수 있습니다.`,
      },
    ],
  },
  {
    id: 3,
    img: honorsclub5,
    title: `예우공간의 주인공`,
    desc: [
      {
        id: 31,
        dec: `유니세프한국위원회 사옥의 후원자 예우공간에 후원 스토리를 게시해 회원님의 뜻을 널리 알립니다.`,
      },
    ],
  },
  {
    id: 4,
    img: honorsclub2,
    title: `유니세프 현장직원 보고`,
    desc: [
      {
        id: 41,
        dec: `수혜국가 유니세프 담당 직원이 후원 내역을 상세히 보고 드립니다.`,
      },
    ],
  },
  {
    id: 5,
    img: honorsclub4,
    title: `맞춤형 후원`,
    desc: [
      {
        id: 51,
        dec: `도움이 필요한 국가와 사업을 지정하시면 그에 맞는 후원 사업 제안서를 드립니다.`,
      },
    ],
  },
  {
    id: 6,
    img: honorsclub6,
    title: `세액 공제 혜택`,
    desc: [
      {
        id: 61,
        dec: `유니세프한국위원회는 지정기부금 단체로, 세액 공제 혜택을 받으실 수 있습니다.`,
      },
    ],
  },
];

const SectionTop = styled(Section)`
  ${SectionHeader} {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;

    .col-dec {
      width: 59%;
      padding-right: 15px;

      p {
        margin-top: 32px;
      }
    }

    ${breakpoint(`tablet`)} {
      flex-wrap: wrap;

      .col-dec {
        width: 100%;
        margin-bottom: 32px;
      }

      .col-img {
        width: 100%;
        text-align: center;

        picture,
        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
`;

const SectionQualification = styled(Section)`
  .list-item {
    width: 50%;

    dl {
      align-items: center;

      h3 {
        strong {
          color: #2d2926;
          font-size: 24px !important;
          line-height: 1;

          ${breakpoint(1160)} {
            font-size: 2.07vw !important;
          }
          ${breakpoint(`tablet`)} {
            font-size: 18px !important;
          }
        }
      }

      > dd {
        padding-left: 24px;

        .dec-ul {
          strong {
            color: #1cabe2;
          }
        }
      }
    }

    ${breakpoint(`tablet`)} {
      width: 100% !important;
      margin-bottom: 48px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }

      dl {
        flex-wrap: wrap;

        dt,
        dd {
          width: 100% !important;
          text-align: center !important;
        }

        dd {
          padding-left: 0;

          h3 {
            text-align: center !important;
          }

          .dec-ul {
            max-width: 180px;
            margin: 0 auto;
          }
        }
      }
    }
  }
`;

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `특별후원팀`,
    tel: `02-721-5150`,
    email: `major@unicef.or.kr`,
  },
];

const SectionHonorsClub = styled(Section)`
  background: #e2f0f6;
  padding: 120px 0;

  .list-item {
    width: 33.33%;
    text-align: left;
    padding-left: 40px;
    margin-bottom: 64px;

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      margin-bottom: 0;
    }

    dt {
      padding: 0;

      .img-wrap {
        width: 80px;
      }
    }

    dd {
      max-width: 270px;

      h3 {
        text-align: left;
      }
    }

    ${breakpoint(`tablet`)} {
      padding-left: 0;

      dd {
        max-width: 200px;
      }
    }
  }
`;

const SwiperCustom = styled(SwiperBasic)`
  padding: 0;
  .swiper-container {
    padding-bottom: 0px;
  }
  .swiper-slide {
    width: 1120px;

    ${breakpoint(`tablet`)} {
      width: 774px;
    }
  }
`;

const SectionWidth = styled(Section)`
  .header-dec {
    margin-top: 32px;
  }

  .process-ol {
    margin: 0 -15px;
    .list-item {
      width: 33.33%;
      padding: 0 15px;
      margin-bottom: 64px;

      dt {
        padding: 0;
        .img-wrap {
          max-width: 256px;
        }
      }

      .dec-ul {
        margin-top: 24px;
      }
    }

    ${breakpoint(`tablet`)} {
      .list-item {
        width: 50%;
      }
    }
  }
`;

const SectionContact = styled(Section)`
  background: #f8f9fa;

  .form-container {
    max-width: 736px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    ${FormGroup} {
      width: 50%;
      padding: 0 16px;
      margin-bottom: 24px;
      &.full {
        width: 100%;
      }
    }

    .chk-label {
      font-size: 14px;
    }
  }
  ${breakpoint(640)} {
    .form-container {
      max-width: auto;
      width: 100%;
      display: block;
      ${FormGroup} {
        width: 100%;
        padding: 0;
      }
    }
  }

  .btn-wrap {
    margin-top: 78px;
    text-align: center;

    .btn {
      width: 100%;
      max-width: 350px;
    }
  }

  .clear {
    display: block;
    width: 100%;
    height: 0;
    clear: both;
    visibility: hidden;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 120px;

  .video-wrap {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    /* padding-top: 66.69%; */
    padding-top: 56.25%;
    position: relative;
  }
  .video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  ${breakpoint(`tablet`)} {
    &::before {
      width: auto;
      height: 0;
      padding-top: 69.44%;
      left: -20px;
      right: -20px;
      transform: translate(0, 0);
    }
  }
`;

export interface HonorsClubData {
  userName: string;
  userMobile: string;
  userEmail: string;
  agreeCheck: boolean;
  formType: string;
  inquiryType?: string;
}

const HonorsClub: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['110', '280', '285', '290'],
        pagePerCount: 8,
        category: ['A017'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link:
              article.boardCategoryCode === '110'
                ? `/about-us/press/${article.boardIndexNumber}`
                : `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  const {
    register,
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm<HonorsClubData>({
    defaultValues: {
      userName: ``,
      userMobile: ``,
      userEmail: ``,
      agreeCheck: false,
      formType: `honors`,
    },
  });
  const popupStore = usePopupStore();
  const onSubmit: SubmitHandler<HonorsClubData> = useCallback(
    async ({
      userName,
      userMobile,
      userEmail,
      agreeCheck,
      formType,
      ...formData
    }) => {
      try {
        const {
          resultCode,
          resultMessage,
        } = await HonorsClubControllerService.insertHonorsQnaUsingPost({
          ...formData,
          name: userName,
          mobile: userMobile,
          email: userEmail,
          isAgreeYn: agreeCheck ? `Y` : `N`,
          type: `honors`,
        });
        popupStore.show(resultMessage);
        reset();
      } catch {
        popupStore.show(`문의 등록에 실패했습니다. 다시 시도해주세요.`);
      }
    },
    [popupStore, reset],
  );

  return (
    <LayoutWithTitle
      location={location}
      title="고액·아너스클럽"
      description="for every child, honor's club"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="col-dec">
              <h2>
                <Tit size="s1">
                  <PageComponent id="title1">
                    가장 특별한 리더들의 모임, <br />
                    유니세프 아너스클럽
                  </PageComponent>
                </Tit>
              </h2>
              <p>
                <PageComponent id="content1">
                  유니세프 아너스클럽은 큰 나눔으로 세상의 변화를 이끄는
                  <br className="m-hide" />
                  후원자들의 모임으로 1억 원 이상 기부하신 회원들로 구성됩니다.
                  <br />
                  어린이를 돕는 가장 가치 있고 명예로운 모임!
                  <br />
                  유니세프 아너스클럽의 회원이 되어주세요.
                </PageComponent>
              </p>
            </div>
            <div className="col-img">
              <Image pcSrc={TopImg} mobileSrc={TopImgM} />
            </div>
          </SectionHeader>
        </Container>
      </SectionTop>

      <Container>
        <VideoContainer>
          <div className="video-wrap">
            <iframe
              className="video"
              src="https://www.youtube.com/embed/xNCS1K-t3XY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </VideoContainer>
      </Container>

      <SectionQualification className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">아너스클럽 자격조건</Tit>
            </h2>
          </SectionHeader>
          <ProcessList itemData={qualification} type="type-2" />
        </Container>
      </SectionQualification>

      <SectionHonorsClub>
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">유니세프 아너스클럽 회원이 되시면</Tit>
            </h2>
          </SectionHeader>

          <SwiperCustom>
            <Swiper
              slidesPerView="auto"
              scrollbar={{ draggable: true }}
              className="scroll-only"
            >
              <SwiperSlide key="1">
                <ProcessList itemData={honorsclub} />
              </SwiperSlide>
            </Swiper>
          </SwiperCustom>
        </Container>
      </SectionHonorsClub>

      <Container>
        <VideoContainer>
          <div className="video-wrap">
            <iframe
              className="video"
              src="https://www.youtube.com/embed/sOT89CzhE4Q"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </VideoContainer>
      </Container>

      <SectionWidth className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">함께하는 분들</Tit>
            </h2>
          </SectionHeader>
          <ProcessList
            itemData={peopleData}
            type="people"
            justifyContent="start"
          />
          <p
            className="header-dec"
            css={`
              text-align: center;
            `}
          >
            기업인과 문화예술인, 스포츠선수 등 각 분야의 유명인사들이 참여하고
            있으며 이 밖에 일반 시민들도 큰 나눔으로 아너스클럽에 함께하고
            있습니다.
          </p>
        </Container>
      </SectionWidth>

      <SectionContact className="by-sub-main-layout">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <SectionHeader className="with-desc">
              <h2>
                <Tit size="s1">고액·아너스클럽 문의</Tit>
              </h2>
            </SectionHeader>
            <div className="form-container">
              <FormGroup>
                <LabelText require>성명</LabelText>
                <InputText
                  placeholder="성명 입력"
                  ref={register({
                    required: {
                      value: true,
                      message: `성명을 입력해주세요.`,
                    },
                    maxLength: {
                      value: 20,
                      message: `20자 이내로 입력해주세요.`,
                    },
                  })}
                  name="userName"
                />
                {errors.userName && (
                  <ErrorMessage>{errors.userName.message}</ErrorMessage>
                )}
              </FormGroup>
              <div className="clear" />
              <FormGroup>
                <LabelText require>연락처</LabelText>
                <Controller
                  control={control}
                  name="userMobile"
                  rules={{
                    required: {
                      value: true,
                      message: `연락처를 입력해주세요.`,
                    },
                    pattern: {
                      value: mobileRegex,
                      message: `올바른 연락처 형식이 아닙니다.`,
                    },
                  }}
                  as={
                    <InputNumber
                      format="###########"
                      placeholder="연락처 입력 ( &lsquo;-&rsquo; 제외 )"
                    />
                  }
                />
                {errors.userMobile && (
                  <ErrorMessage>{errors.userMobile.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <LabelText require>이메일</LabelText>
                <InputText
                  name="userEmail"
                  ref={register({
                    required: {
                      value: true,
                      message: `이메일을 입력해주세요.`,
                    },
                    pattern: {
                      value: emailRegex,
                      message: `올바른 이메일 형식이 아닙니다.`,
                    },
                    maxLength: {
                      value: 100,
                      message: `100자 이내로 입력해주세요.`,
                    },
                  })}
                  placeholder="이메일주소 입력"
                />
                {errors.userEmail && (
                  <ErrorMessage>{errors.userEmail.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup className="full">
                <InputCheckbox
                  className="chk-label"
                  ref={register({
                    required: {
                      value: true,
                      message: `약관 동의에 체크해주세요.`,
                    },
                  })}
                  name="agreeCheck"
                  label="예, 저는 아너스클럽 안내를 받고 싶습니다. 그리고 안내를 원치 않을 시, 언제든지 수신거부 할 수 있음을 잘 알고 있습니다."
                />
                {errors.agreeCheck && (
                  <ErrorMessage>{errors.agreeCheck.message}</ErrorMessage>
                )}
              </FormGroup>
            </div>
            <div className="btn-wrap">
              <div className="clear" />
              <Button className="btn" type="submit">
                문의하기
              </Button>
            </div>
          </Container>
        </form>
      </SectionContact>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A017" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default HonorsClub;
