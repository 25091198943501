import AnchorText from './AnchorText';
import InputCheckbox from './Input/InputCheckbox';
import LayerPopup from './LayerPopup';
import React, {
  ChangeEventHandler,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const AgreementContainer = styled.div``;

const AgreementItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;

  &.all-chk {
    border-bottom: 1px solid #e5e6e8;
    padding: 17px 0;
    margin-bottom: 10px;
  }
`;
const OpenButton = styled(AnchorText)`
  color: #828385;
`;

interface Policy {
  label: string;
  content: ReactElement;
  required?: boolean;
}

interface AgreementProps extends InputHTMLAttributes<HTMLInputElement> {
  policies: Policy[];
  labels?: {
    agreeAll: string;
    required: string;
    optional: string;
    more: string;
  };
  allChk?: boolean;
}

const Agreement = forwardRef<HTMLInputElement, AgreementProps>(
  (
    {
      allChk,
      policies,
      labels = {
        agreeAll: `모두 동의합니다.`,
        required: `[필수]`,
        optional: `[선택]`,
        more: `보기`,
      },
      onBlur,
      ...props
    },
    ref,
  ) => {
    const { register, watch, setValue } = useForm();
    const [selected, setSelected] = useState<Policy>();

    const policyIds = useMemo(
      () => policies.map((_item, index) => `policy${index}`),
      [policies],
    );
    const policyValues = watch(policyIds);
    // 모든 항목을 동의했는지 여부
    const allAgreed = useMemo(
      () =>
        Object.values(policyValues).reduce(
          (result, value) => result && value,
          true,
        ),
      [policyValues],
    );
    // 필수동의를 모두 동의했는지 여부
    const allRequiredAgreed = useMemo(
      () =>
        policies
          .filter((policy) => policy.required)
          .reduce(
            (result, policy) =>
              result && policyValues[`policy${policies.indexOf(policy)}`],
            true,
          ),
      [policies, policyValues],
    );

    // 모두 동의된 상태로 계산되면, 전체동의 체크박스도 활성화
    useEffect(() => {
      setValue(`agreeAll`, allAgreed);
    }, [allAgreed, setValue]);

    const handleAgreeAll: ChangeEventHandler<HTMLInputElement> = (e) => {
      policies.forEach((_item, index) =>
        setValue(`policy${index}`, e.target.checked),
      );
    };

    const openModal = (policy: Policy) => {
      setSelected(policy);
    };

    return (
      <AgreementContainer className="agreement-container" onBlur={onBlur}>
        {/* 상위 폼과 연결되는 readonly input */}
        <input
          {...props}
          ref={ref}
          type="checkbox"
          checked={allRequiredAgreed || false}
          readOnly
          style={{ height: 0, position: `absolute`, width: 0 }}
        />
        {allChk && (
          <AgreementItem className="all-chk">
            <InputCheckbox
              name="agreeAll"
              ref={register}
              onChange={handleAgreeAll}
              label={labels.agreeAll}
            />
          </AgreementItem>
        )}
        {policies.map((policy, index) => (
          <div key={policy.label}>
            <AgreementItem>
              <InputCheckbox
                name={`policy${index}`}
                ref={register({ required: policy.required })}
                label={`${
                  policy.required ? labels.required : labels.optional
                } ${policy.label}`}
              />
              <OpenButton onClick={() => openModal(policy)}>
                {labels.more}
              </OpenButton>
            </AgreementItem>
          </div>
        ))}
        {selected && (
          <LayerPopup
            title={selected.label}
            onRequestClose={() => setSelected(undefined)}
          >
            <p>{selected.content}</p>
          </LayerPopup>
        )}
      </AgreementContainer>
    );
  },
);

export default Agreement;
